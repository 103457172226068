import { IApplication } from './application';

export const TaxManger: IApplication = {
  id: 1,
  applicationName: 'Tax Manager'
};

export const CaViewer: IApplication = {
  id: 2,
  applicationName: 'CA viewer'
};

export const applications = [TaxManger, CaViewer];
